import React from 'react'
import styled from 'styled-components'
import { DomainProgress } from '../domainProgress'
import { DetailPerformance } from '../detailPerformance';
import Select from 'react-select';

const WrapDetailReport = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  .wrap-select {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
`

const ReportDetailCohort = (props) => {
  const { setShowDetail, listOptionCohort, showDetail } = props

  return (
    <WrapDetailReport>
      <div className="wrap-select">
        <Select
          className={`react-select-common w-50`}
          placeholder="Select item"
          options={listOptionCohort}
          classNamePrefix="react-select-custom"
          value={showDetail}
          onChange={val => {
            setShowDetail(val)
          }}
        />
      </div>
      <DomainProgress />
      <DetailPerformance type="cohort" />
    </WrapDetailReport>
  )
}

export default ReportDetailCohort
