import React from 'react'
import styled from "styled-components";
import ReactECharts from 'echarts-for-react';

const Wrapper = styled.div`
  width: 100%;
`

const ChartBasicBar = () => {

  const option = {
    legend: {
      orient: "vertical", // Đặt legend theo chiều dọc
      right: "right", // Căn trái
      top: "20%",
    },
    grid: {
      left: 100,
      right: 200,
      top: 50,
      bottom: 50
    },
    xAxis: {
      type: 'category',
      data: ['Jul 1', 'Jul 7', 'Jul 13', 'Jul 18', 'Jul 22', 'Jul 26', 'Jul 31']
    },
    yAxis: {
      type: 'value'
    },
    series: [
      {
        data: [120, 200, 150, 80, 70, 110, 130],
        name: "Disease Knowledge",
        type: 'bar'
      }
    ],
    color: '#AAF0C6'
  };

  return (
    <Wrapper>
      <ReactECharts option={option} />
    </Wrapper>
  )
}

export default ChartBasicBar
