import React, { useState, useEffect } from "react";

//components 
import { InstructorVoiceChat } from ".";

//react-router-dom
import { useParams } from "react-router-dom";

//redux
import { useDispatch, useSelector } from "react-redux";

//lodash
// import _ from "lodash";

//constants
// import { questionConstants } from "@constants";

//call Action
import { actFetchCheckMissingTask } from "@actions";

function InstructorChatFrame(props) {
  //variables
  const dispatch = useDispatch();
  let { id } = useParams();

  //state
  const [isLoading] = useState(false);

  useEffect(() => {
    dispatch(actFetchCheckMissingTask(id));
  }, [dispatch, id])

  //Check Missing Task
  const missingTaskStatus = useSelector((state) => (state?.checkMissingTask?.data) || {});
  const isPendingQuestion = missingTaskStatus?.status === "PendingQuestion";
  return (
    <InstructorVoiceChat
      {...props}
      isLoading={isLoading}
      isPendingQuestion={isPendingQuestion}
    />
  );
}

export default InstructorChatFrame;