import React from 'react';
import styled from 'styled-components';
import { MessageImage, MessageInfo, MessageButton } from '@components/common/MessageEndChat';

const ButtonContainer = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: white;
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
    padding: 24px 48px;
    display: flex;
    justify-content: center;
    border-radius: 6px;
`;

function MessageBoxEndChat(props) {
    const { TaskToResume } = props || {};
    return (
        <ButtonContainer>
            <div className="message-answer w-100 p-0" style={{backgroundColor: 'transparent'}}>
                <MessageImage />
                <MessageInfo
                    title={`Med-X-traordinary!`}
                    content={TaskToResume?.Statement}
                />
                <MessageButton {...props} />
            </div>
        </ButtonContainer>
    );
}

export default MessageBoxEndChat;