import adapter from 'webrtc-adapter';
import { useState, useEffect } from 'react';
import { RecordRTCPromisesHandler } from 'recordrtc';
import axios from 'axios';
import { useSelector } from "react-redux";

const AUDIO_TYPE = 'audio';
const MODEL = 'whisper-1';
const TRANSCRIPTIONS_API_URL = 'https://api.openai.com/v1/audio/transcriptions';

const useWhisperSTT = (apiKey) => {
  const [recorder, setRecorder] = useState(null);
  const [stream, setStream] = useState(null);
  const [status, setStatus] = useState('stopped'); // 'stopped', 'recording', 'paused'
  const [error, setError] = useState(null); // Manage errors

  const caseData = useSelector((state) => state.cases || []);
  const caseResponse = caseData?.data;
  const InstructorCharacters = caseResponse?.Case?.Characters?.Instructor?.Character;
  const IsoCode = InstructorCharacters?.Language?.IsoCode?.code || 'en';

  // Validate API key within the functions
  function validateApiKey() {
    if (!apiKey) {
      setError('API key is required');
      return false;
    }
    return true;
  }

  function handleError(error) {
    console.error(error.message);
    setError(error.message);  // Set error state instead of throwing
    resetRecordingState();
  }

  // Start Recording
  async function startRecording() {
    if (!validateApiKey()) return;

    try {
      const audioStream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const audioRecorder = new RecordRTCPromisesHandler(audioStream, { type: AUDIO_TYPE });
      await audioRecorder.startRecording();
      setStream(audioStream);
      setRecorder(audioRecorder);
      setStatus('recording');
    } catch (error) {
      handleError(error);
    }
  }

  // Stop Recording
  async function stopRecording(onFinish) {
    if (status !== 'recording' || !recorder) {
      return handleError(new Error('Cannot stop recording: no recorder or recording in progress'));
    }

    try {
      await recorder.stopRecording();
      const blob = await recorder.getBlob();
      await transcribe(blob, onFinish, IsoCode);
      cleanupStream();
    } catch (error) {
      handleError(error);
    }
  }

  // Pause Recording
  async function pauseRecording() {
    if (status !== 'recording' || !recorder) {
      return handleError(new Error('Cannot pause recording: no recorder or recording in progress'));
    }

    try {
      await recorder.pauseRecording();
      setStatus('paused');
    } catch (error) {
      handleError(error);
    }
  }

  // Resume Recording
  async function resumeRecording() {
    if (status !== 'paused' || !recorder) {
      return handleError(new Error('Cannot resume recording: no recorder or paused recording in progress'));
    }

    try {
      await recorder.resumeRecording();
      setStatus('recording');
    } catch (error) {
      handleError(error);
    }
  }

  // Transcribe Audio
  async function transcribe(audioBlob, onFinish, language) {
    const formData = new FormData();
    formData.append('file', audioBlob, 'audio.wav');
    formData.append('model', MODEL);
    formData.append('language', language);

    const headers = {
      Authorization: `Bearer ${apiKey}`,
      'Content-Type': 'multipart/form-data',
    };

    try {
      const response = await axios.post(TRANSCRIPTIONS_API_URL, formData, { headers });
      onFinish(response.data?.text || '');
    } catch (error) {
      handleError(new Error('Error transcribing audio'));
    }
  }

  // Cleanup the media stream
  function cleanupStream() {
    if (stream) {
      stream.getTracks().forEach((track) => track.stop());
    }
    resetRecordingState();
  }

  // Reset Recording State
  function resetRecordingState() {
    setRecorder(null);
    setStream(null);
    setStatus('stopped');
  }

  useEffect(() => {
    console.log(adapter.browserDetails.browser);
    console.log(adapter.browserDetails.version);
  }, []);

  return {
    isRecording: status === 'recording',
    isStopped: status === 'stopped',
    isPaused: status === 'paused',
    error,  // Expose error state
    startRecording,
    stopRecording,
    pauseRecording,
    resumeRecording,
  };
};

export default useWhisperSTT;