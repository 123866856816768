// IconButton.jsx
import React from 'react';
import styled, { css, keyframes } from 'styled-components';
import IconMicro from '@images/chatvoice/micro-icon.svg';
import IconPause from '@images/chatvoice/pause-icon.svg';

const pulse = keyframes`
  0% {
    transform: scale(0);
    opacity: 0;
    border: 65px solid #2268F0;
  }
  50% {
    border: solid #2268F0;
    opacity: 0.8;
  }
  90% {
    transform: scale(3.2);
    opacity: 0.2;
    border: 3px solid #2268F0;
  }
  100% {
    transform: scale(3.3);
    opacity: 0;
    border: 1px solid #2268F0;
  }
`;

const shake = keyframes`
  0%, 100% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-4px);
  }
  50% {
    transform: translateX(4px);
  }
  75% {
    transform: translateX(-4px);
  }
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
  margin-bottom: 80px;

  .object {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  .outline {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 40px solid #2268F0;
    position: absolute;
    ${({ isRecording }) =>
    isRecording &&
    css`
        animation: ${pulse} 2s ease-out infinite;
      `}
  }

  .button {
    width: 118px;
    height: 118px;
    border-radius: 50%;
    background: #2268f0;
    opacity: 0.34;
    cursor: pointer;
    transition: transform 0.2s;
    &:active {
      transform: scale(0.95);
      animation: ${shake} 0.3s linear;
    }
  }

  .icon-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 52px;
    height: 52px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .icon {
    max-width: 100%;
    max-height: 100%;
  }
`;

const Text = styled.div`
  color: #000;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  margin-top: 15px;
`;

export default function IconButton({ isRecording, onClick }) {
  return (
    <Box isRecording={isRecording}>
      <div style={{ cursor: 'pointer' }} className="object" onClick={onClick}>
        <div className="outline" />
        <div className="button" />
        <div className="icon-wrapper">
          <img
            src={isRecording ? IconPause : IconMicro}
            alt={isRecording ? 'Stop Recording' : 'Start Recording'}
            className="icon"
          />
        </div>
      </div>
      <Text>{isRecording ? 'Stop talking' : 'Press to Talk'}</Text>
    </Box>
  );
}