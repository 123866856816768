import { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import apiCaller from "@utils/apiCaller";

//redux
import { useSelector } from "react-redux";

const TextToSpeech = ({ contentSpeechAudio, onAudioStart, onAudioEnd, onStatusChange }) => {
    const [audioObject, setAudioObject] = useState(null);
    const [audioContext, setAudioContext] = useState(null);
    const history = useHistory();
    const audioRef = useRef(null);

    //get data store
    const caseData = useSelector((state) => state.cases || []);
    const InstructorCharacter = caseData?.data?.Case?.Characters?.Instructor?.Character;
    const AgeInteractingCharacter = InstructorCharacter?.Demographics?.age;
    const GenderInteractingCharacter = InstructorCharacter?.Demographics?.gender;

    useEffect(() => {
        const unlisten = history.listen(() => {
            if (audioRef.current) {
                audioRef.current.stop();
                audioRef.current.disconnect();
                audioRef.current = null;
            }
            if (audioContext && audioContext.state !== 'closed') {
                audioContext.close();
                setAudioContext(null);
            }
        });
    
        return () => {
            unlisten();
            if (audioRef.current) {
                audioRef.current.stop();
                audioRef.current.disconnect();
                audioRef.current = null;
            }
            if (audioContext && audioContext.state !== 'closed') {
                audioContext.close();
                setAudioContext(null);
            }
        };
    }, [history, audioContext]);

    useEffect(() => {
        // Nếu có contentSpeechAudio, phát âm thanh
        if (contentSpeechAudio) {
            handleStreamAudio(contentSpeechAudio);
        }

        // Cleanup khi component bị unmount hoặc khi contentSpeechAudio thay đổi
        return () => {
            if (audioRef.current) {
                audioRef.current.stop();
                audioRef.current.disconnect(); // Ngắt kết nối
                audioRef.current = null;
            }
            if (audioContext) {
                audioContext.close();
                setAudioContext(null);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contentSpeechAudio]);

    const handleStreamAudio = async (text) => {
        if (onStatusChange) onStatusChange('streaming');
    
        try {
            const params = {
                input: text,
                gender: GenderInteractingCharacter,
                age: parseInt(AgeInteractingCharacter) || 0
            };
    
            const response = await apiCaller(
                `/api/binh/tts_streaming/`,
                'POST',
                params,
                false,
                { responseType: 'arraybuffer' }
            );
    
            if (response && response.status === 200 && response.data) {
                if (!(response.data instanceof ArrayBuffer)) {
                    throw new Error("Invalid data format");
                }
    
                let newAudioContext = audioContext;
    
                // Only create a new context if there's none or it's already closed
                if (!newAudioContext || newAudioContext.state === 'closed') {
                    newAudioContext = new (window.AudioContext || window.webkitAudioContext)();
                    setAudioContext(newAudioContext);
                }
    
                newAudioContext.decodeAudioData(response.data, (buffer) => {
                    const source = newAudioContext.createBufferSource();
                    source.buffer = buffer;
                    source.connect(newAudioContext.destination);
    
                    if (onAudioStart) onAudioStart();
    
                    source.start(0);
                    setAudioObject(source);
                    audioRef.current = source;
    
                    if (onStatusChange) onStatusChange('playing');
    
                    source.onended = () => {
                        handleAudioEnd();
                        
                        // Close the context only if it hasn't been closed yet
                        if (newAudioContext.state !== 'closed') {
                            newAudioContext.close();
                            setAudioContext(null);
                        }
                    };
                }, (error) => {
                    console.error('Error decoding audio:', error);
                    if (onStatusChange) onStatusChange('error');
                });
            } else {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
        } catch (error) {
            if (onStatusChange) onStatusChange('error');
            console.error('Error streaming audio:', error);
        }
    };

    const handleAudioEnd = () => {
        if (onAudioEnd) onAudioEnd(); // Gọi hàm onAudioEnd khi âm thanh kết thúc
        if (onStatusChange) onStatusChange('ended'); // Báo cáo trạng thái đã kết thúc

        if (audioObject) {
            audioObject.stop();
            setAudioObject(null);
        }
    };

    return null; // Trả về null nếu không cần render gì
};

export default TextToSpeech;