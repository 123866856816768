import React from 'react'
import { Icon } from '@components/common'
import Back from '@images/teachV2/back.svg'
import styled from 'styled-components'
import ContentInfomation from './ContentInfomation'
import { DomainProgress } from '../domainProgress'
import { DetailPerformance } from '../detailPerformance';

const WrapDetailReport = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  .wrap-back {
    display: flex;
    align-items: center;
    cursor: pointer;
    gap: 8px;
    span {
      font-size: 14px;
      font-weight: 600;
      color: #081B2A;
    }
  }
`

const ReportDetail = (props) => {
  const { setShowDetail } = props

  const handleBack = () => {
    setShowDetail(false)
  }

  return (
    <WrapDetailReport>
      <div className="wrap-back" onClick={handleBack}>
        <Icon src={Back} width="20" stroke="#000"/>
        <span>Back</span>
      </div>
      <ContentInfomation />
      <DomainProgress />
      <DetailPerformance type="learner" />
    </WrapDetailReport>
  )
}

export default ReportDetail
