import React, { useState } from 'react'
import styled from 'styled-components'

const WrapGuideDialog = styled.div`
  padding: 20px;
  border-radius: 16px;
  border: 1px solid #E5E5E5;
  display: flex;
  flex-direction: column;
  gap: 16px;
  .dialog-header {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
    span {
      width: 9px;
      height: 9px;
      background-color: #17B26A;
      border-radius: 50%;
    }
    h1 {
      font-size: 18px;
      font-weight: 600;
      line-height: 28px;
      color: #101828;
    }
  }
`

const WrapItemGuildeDialog = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

const ListDialog = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 6px;
  .dialog-item {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding-top: 16px;
    cursor: pointer;
    width: 100%;
    &__content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      height: 84px;
      padding: 0 18px;
      h1 {
        font-size: 14px;
        font-weight: 600;
        line-height: 18px;
        text-align: center;
      }
      &-percent {
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
        background: #fff;
        border-radius: 999px;
        padding: 2px 10px;
      }
    }
    span {
      width: 100%;
      height: 7px;
    }
  }
`

const ListQuestion = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  .question-list {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 8px;
    border-bottom: 1px solid #38916C;
    .question-title {
      padding: 8px 12px;
      border: 1px solid transparent;
      cursor: pointer;
      h1 {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: #98A2B3;
      }
    }
  }
  .question-content {
    p {
      font-size: 18px;
      font-weight: 600;
      line-height: 28px;
      span {
        color: #000000;
      }
    }
  }
`

const sidebarInit = [
  {
    "index": 0,
    "title": "Disease Knowledge",
    "percent": "56%",
    "bg": "#FDFCF0",
    "color": "#F79009",
  },
  {
    "index": 1,
    "title": "Medical Science Knowledge",
    "percent": "84%",
    "bg": "#ECFDF3",
    "color": "#067647",
  },
  {
    "index": 2,
    "title": "Reasoning Principles",
    "percent": "38%",    
    "bg": "#FEE4E2",
    "color": "#F04438",
  },
  {
    "index": 3,
    "title": "Applied Reasoning",
    "percent": "78%",
    "bg": "#ECF6FD",
    "color": "#1E73B0",
  },
  {
    "index": 4,
    "title": "Pattern Recognition",
    "percent": "40%",
    "bg": "#FDFCF0",
    "color": "#F79009",
  },
  {
    "index": 5,
    "title": "Decision-Making Efficiency",
    "percent": "82%",
    "bg": "#ECFDF3",
    "color": "#067647",
  }
]

const listQuestion = [
  {
    title: "Question 111",
    question: " In this case, what is the mechanism explaining the patient’s clinical symptoms (stiffening, jerking, unresponsiveness, and fever)?"
  },
  {
    title: "Question 112",
    question: " In this case, what is the mechanism explaining the patient’s clinical symptoms (stiffening, jerking, unresponsiveness, and fever)?"
  },
  {
    title: "Question 113",
    question: " In this case, what is the mechanism explaining the patient’s clinical symptoms (stiffening, jerking, unresponsiveness, and fever)?"
  },
  {
    title: "Question 114",
    question: " In this case, what is the mechanism explaining the patient’s clinical symptoms (stiffening, jerking, unresponsiveness, and fever)?"
  }
]


const GuideDialog = () => {
  const [activeTab, setActiveTab] = useState(sidebarInit[0]);
  const [activeQuestion, setActiveQuestion] = useState(0);

  return (
    <WrapGuideDialog>
      <div className="dialog-header">
        <span></span>
        <h1>Guided Dialog</h1>
      </div>
      <WrapItemGuildeDialog> 
        <ListDialog>
          {sidebarInit.map((item, index) => (
            <div 
              key={index}
              className={`dialog-item`}
              onClick={() => setActiveTab(item)}
              style={{ background: `${item?.bg}`}}
            >
              <div className='dialog-item__content'>
                <h1 style={{ color: `${item?.color}`}}>{item?.title}</h1>
                <p style={{ color: `${item?.color}`}} className='dialog-item__content-percent'>
                  {item?.percent}
                </p>
              </div>
              <span style={{ background: `${index === activeTab?.index ? item.color : "transparent"}` }}></span>
            </div>
          ))}
        </ListDialog>
        <ListQuestion>
          <div className='question-list'>
            {listQuestion?.map((item, index) => (
              <div 
                key={index} 
                className={`question-title`}
                style={{ background: `${index === activeQuestion ? activeTab?.bg : 'transparent'}`, borderBottom: `1px solid ${index === activeQuestion ? activeTab?.color : "transparent"}`}}
                onClick={() => setActiveQuestion(index)}
              >
                <h1 style={{ color: `${index === activeQuestion ? activeTab?.color : '#98A2B3'}`}}>{item?.title}</h1>
              </div>
            ))}
          </div>
          <div className="question-content">
            <p style={{ color: `${activeTab?.color}`}} >
              {listQuestion[activeQuestion]?.title}
              <span>{listQuestion[activeQuestion]?.question}</span>
            </p>
          </div>
        </ListQuestion>
      </WrapItemGuildeDialog>
    </WrapGuideDialog>
  )
}

export default GuideDialog
