import React, { useState } from 'react'
import { Icon } from '@components/common'
import Feedback from '@images/teachV2/feedback.svg'
import Save from '@images/teachV2/save-flag.svg'
import Saved from '@images/teachV2/saved-flag.svg'
import ReactTooltip from 'react-tooltip';
import { apiCaller } from "@utils";
import { toast } from 'react-toastify';
import ResultMedia from './ResultMedia'
import Copy from '@images/teachV2/copy.svg'
import check from '@images/teachV2/check-done.svg'
import { Button } from 'reactstrap'
import Scenario from '@images/teachV2/scenario.svg'
import ResultExplanation from './ResultExplanation'
import { useDispatch } from 'react-redux'
import { generateExplanation } from "@actions"

const ResultItem = (props) => {
  const { index, dataContentFile, question, setShowModal, setDataFeedback, activeTab, setDataContentFile, } = props
  const [isSaved, setIsSaved] = useState(false)
  const [mediaId, setMediaId] = useState(question?.media[0]?.id)
  const [showCheckDone, setShowCheckDone] = useState(false)
  const [showBtnExplan, setShowBtnExplan] = useState(true)
  const dispatch = useDispatch();
  
  const copy = (questionText, listAnswers) => {
    setShowCheckDone(true)
    const answers = listAnswers.map((ans) => {
      if (ans.id === question?.best_answer?.id) {
        return `${ans.id}. ${ans.content} **`
      } else {
        return `${ans.id}. ${ans.content}`
      }
    })
    const text = questionText.concat(" \n", answers.join(" \n"))
    navigator.clipboard.writeText(text)
    setTimeout(() => {
      setShowCheckDone(false)
    }, 2000)
  }

  const handleFeedback = (question, listAnswers) => {
    setShowModal(true)
    const answers = listAnswers.map((ans) => `${ans.id}. ${ans.content}`)
    const text = question.concat(" \n", answers.join(" \n"))
    setDataFeedback(text)
  }

  const saveQuestionBank = (id) => {
    let params
    if (dataContentFile[id]?.media[0]?.id) {
      params = {
        "action_type": "save_to_question_bank",
        "contents": dataContentFile[id],
        "media_item_id": mediaId
      }
    } else {
      params = {
        "action_type": "save_to_question_bank",
        "contents": dataContentFile[id],
      }
    }
    apiCaller(`/api/medicalcase/functional/`, "POST", params)
      .then(response => {
        if (response.status === 200) {
          toast.success('Successfully saved to Question Bank', {
            position: "bottom-right",
            autoClose: 2000,
          });
          setIsSaved(true)
        }
      })
      .catch(error => {
        console.error("Error in API call", error);
        toast.warning('Save question bank was failed!', {
          position: "bottom-right",
          autoClose: 2000,
        });
      });
  }

  const handleMcqExplanation = async (id) => {
    const params = {
      action_type: "mcq_explanation",
      data: question?.question,
      best_answer: question?.best_answer?.id
    }
    const dataExplan = await dispatch(generateExplanation(params))
    if (dataExplan?.data?.status === 200) {
      const listExplanation = dataExplan?.data?.data?.reduce((obj, cur) => ({...obj, [(cur.id)]: cur}), {})
      const newData = dataContentFile?.map((filt) => {
        if (filt?.id === id) {
          return {
            ...filt,
            explanation: listExplanation
          }
        } else {
          return filt
        }
      })
      setShowBtnExplan(false)
      setDataContentFile(newData)
    } else {
      toast.warning('Generate explanation was failed!', {
        position: "bottom-right",
        autoClose: 2000,
      });
    }
  }

  return (
    <div className={`question-item ${activeTab === question.id ? 'active' : ''}`}>
      <div className="question-item__quest">
        <div className='question-item__quest-header'>
          <p>Version #{index}</p>
          <div className='action-question d-flex align-items-center'>
            {showBtnExplan && (
              <Button
                className="btn-sm d-flex align-items-center mr-1"
                color="secondary"
                onClick={() => handleMcqExplanation(index)}
              >
                <Icon src={Scenario} />
                <span className="pl-1">Generate Answer Choice Explanations</span>
              </Button>
            )}
            <div
              data-tip
              data-for={`save-${index}`}
              className={`action-copy p-1 ${isSaved && "disable-save"}`}
              onClick={!isSaved ? () => saveQuestionBank(index) : () => console.log("Saved")}
            >
              <Icon src={isSaved ? Saved : Save}  stroke="#475467"/>
            </div>
            <ReactTooltip id={`save-${index}`} place='top' effect='solid' className="mcq-tooltip">
              <span>{isSaved ? "Question already saved" : "Save To Question Bank Under My Inventory"}</span>
            </ReactTooltip>
            <div 
              data-tip
              data-for={`copy-${index}`}
              className="action-copy p-1" 
              onClick={(e) => copy(question?.question, question.multiple_choices)}
            >
              <Icon src={showCheckDone ? check : Copy}/>
            </div>
            <ReactTooltip id={`copy-${index}`} place='top' effect='solid' className="case-tooltip">
              <span>Copy This Question</span>
            </ReactTooltip>
            <div 
              data-tip
              data-for={`feedback-${index}`}
              className="action-copy p-1" 
              onClick={() => handleFeedback(question?.question, question.multiple_choices)}
            >
              <Icon src={Feedback}/>
            </div>
            <ReactTooltip id={`feedback-${index}`} place='top' effect='solid' className="case-tooltip">
              <span>Report Issues With This Question</span>
            </ReactTooltip>
          </div>
        </div>
        <p className='quest-text'>{question?.question}</p>
      </div>
      <div className="question-item__answers">
        {question.multiple_choices?.map((choice, i) => (
          // <p key={i} className={`${choice.id === question?.best_answer?.id ? "correct-answer" : ""} answer`}>
          //   {choice.id}. {choice.content}
          // </p>
          <ResultExplanation 
            key={i}
            choice={choice} 
            question={question} 
          />
        ))}
      </div>
      {question?.media?.length > 0 && (
        <div className="question-item__explanation w-100">
          <p className='mb-2'>Media </p>
          <ResultMedia question={question} setMediaId={setMediaId} mediaId={mediaId} {...props}/>
        </div>
      )}
      <div className="question-item__explanation">
        <p>Overall Explanation: 
          <div dangerouslySetInnerHTML={{ __html: question?.best_answer?.explanation }} />
        </p>
      </div>
    </div>
  )
}

export default ResultItem
