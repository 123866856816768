import React, { useEffect, useState } from 'react'
import ReportDetailCohort from './ReportDetailCohort'
import styled from 'styled-components'
import Select from 'react-select';
import { useDispatch } from 'react-redux';
import { atcLoadAllCohorts } from "@actions";

const WrapSelectCohort = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: 150px 0;
  gap: 20px;
  .cohort-title {
    width: 458px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    text-align: center;
    align-items: center;
    h1 {
      font-size: 24px;
      font-weight: 600;
      line-height: 32px;
    }    
    p {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
    }
  }
  .cohort-select {
    width: 458px;
  }
`

const ReportByCohort = (props) => {
  const dispatch = useDispatch();
  const [showDetail, setShowDetail] = useState(null)
  const [listOptionCohort, setListOptionCohort] = useState([]);

  const getListCohort = async () => {
    const listCohort = await dispatch(atcLoadAllCohorts());
    if(listCohort.data.status === 200) {
      const convertListCohort = listCohort?.data?.data?.map((cohort) => ({
        value: cohort.CohortId,
        label: cohort.Name,
      }))
      setListOptionCohort(convertListCohort)
    }
  }

  useEffect(() => {
    getListCohort()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      {!showDetail ? (
        <WrapSelectCohort>
          <div className="cohort-title">
            <h1>Select a Cohort to View the Report</h1>
            <p>Please choose a cohort from the list below to access detailed performance metrics and insights for that group.</p>
          </div>
          <div className="cohort-select">
            <Select
              className={`react-select-common w-100`}
              placeholder="Select item"
              options={listOptionCohort}
              classNamePrefix="react-select-custom"
              value={showDetail}
              onChange={val => {
                setShowDetail(val)
              }}
            />
          </div>
        </WrapSelectCohort>
      ) : (
        <ReportDetailCohort setShowDetail={setShowDetail} showDetail={showDetail} listOptionCohort={listOptionCohort}/>
      )}
    </div>
  )
}

export default ReportByCohort
