import styled, { css } from 'styled-components';

export const StyledImage = styled.img`
    width: 110px;
    height: 110px;
    flex-shrink: 0;
    border-radius: 50%;
    object-fit: cover;
    background: #fff;
    @media (max-width: 768px) {
        width: 80px;
        height: 80px;
    }
`;

export const PatientLabel = styled.div`
    color: #5ecdfb;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    text-align: center;
    margin-top: 20px;
`;

export const ImageLabelWrapper = styled.div`
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    width: 100%;
    height: 100%;
    padding-top: 50px;
    @media (max-width: 768px) {
        padding-top: 30px;
    }
`;

export const ResponseText = styled.div`
    color: #fff;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    max-width: 70%;
    margin-left: auto;
    margin-right: auto;
    max-height: 30%;
    overflow-y: auto;
    margin-top: 20px;
    @media (max-width: 768px) {
        font-size: 14px;
        max-width: 90%;
        max-height: 38%;
    }
`;

export const BlinkingDiv = styled.div`
    position: relative;
    ${({ audioStarted }) => audioStarted && css`
        &:before, &:after {
            content: "";
            width: 120px;
            height: 120px;
            border: 14px solid rgba(20, 91, 173, 0.8);
            display: block;
            border-radius: 50%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: -1;
            @media (max-width: 768px) {
                width: 100px;
                height: 100px;
            }
        }
        &:before {
            animation: hWnWDR 0.75s cubic-bezier(.5,0,1,1) infinite alternate;
        }
        &:after {
            animation: hWnWDR 0.75s cubic-bezier(.5,0,1,1) infinite alternate;
            animation-delay: 0.375s;
        }

        @keyframes hWnWDR {
            from {
                transform: translate(-50%, -50%) scale(1);
                opacity: 0.8;
            }
            to {
                transform: translate(-50%, -50%) scale(1.2);
                opacity: 0.4;
            }
        }
    `}
`;