import React from 'react'
import styled from "styled-components";
import ReactECharts from 'echarts-for-react';

const Wrapper = styled.div`
  width: 100%;
`

const ChartRadar = () => {

  const option = {
    radar: {
      // shape: 'circle',
      indicator: [
        { name: 'Disease Knowledge', max: 6500 },
        { name: 'Medical Science Knowledge', max: 16000 },
        { name: 'Reasoning Principles', max: 30000 },
        { name: 'Pattern Recognition', max: 38000 },
        { name: 'Decision-Making Efficiency', max: 52000 },
      ],
    },
    series: [
      {
        name: 'Budget vs spending',
        type: 'radar',
        data: [
          {
            value: [4200, 3000, 20000, 35000, 50000],
            name: '',
            symbol: "none",
            lineStyle: {
              color: "#0BA5EC", // Màu của đường line
              width: 2, // Độ dày đường line
              type: "solid", // Kiểu nét (solid, dashed, dotted)
            },
            areaStyle: {
              color: "#79DDFC61"
            }
          },
        ]
      }
    ]
  };

  return (
    <Wrapper>
      <ReactECharts option={option} />
    </Wrapper>
  )
}

export default ChartRadar
